
import { puntajesMinMax } from '../../utils';
import { calculadoraPsu } from '../../utils/puntajes';

const namespace = true;
const state = {
  postulante: {},
  postulacion: {},
  situaciones: [],
  situacionesPace: [],
  bonificacionPreferenciaPace: [],
  notaSimulacion: null
};

const getters = {
  tienePostulacion({ postulacion }) {
    return !!Object.keys(postulacion).length;
  },
  carrerasPostulacion({ postulacion }, getters, { Universidades }) {
    const { selectUniversidades } = Universidades;
    if (!selectUniversidades.length || !getters.tienePostulacion) {
      return [];
    }
    const carreras = [];
    for (let index = 1; index < 21; index++) {
      if (postulacion[`carCodigo${index}Prefer`] && postulacion[`carCodigo${index}Prefer`] != -1) {
        carreras.push(postulacion[`carCodigo${index}Prefer`]);
      }
    }
    return carreras.map(codigoCarrera => {
      const uniCodigo = Math.floor(codigoCarrera / 1000);
      const universidad = selectUniversidades.find(({ codigo }) => codigo == uniCodigo);
      const carrera = window.INIT.carreras.find(({ codigo }) => codigo == codigoCarrera);
      return {
        ...carrera,
        universidad
      };
    });
  },
  getMaximosPuntajes({ postulante }, { getPuntajes }, { modo }) {
    if (modo != 'oficial') {
      const condicion = (prueba, rendicion, acc, i) => {
        if (rendicion[prueba] > getPuntajes[acc[prueba]][prueba]) {
          return i;
        } else {
          return acc[prueba];
        }
      };
      return getPuntajes.reduce((acc, rendicion, i) => {
        if (i == 0) {
          return acc;
        }
        return {
          leng: condicion('leng', rendicion, acc, i),
          mate1: condicion('mate1', rendicion, acc, i),
          hcso: condicion('hcso', rendicion, acc, i),
          cien: condicion('cien', rendicion, acc, i),
          mate2: condicion('mate2', rendicion, acc, i),
        };
      }, {
        leng: 0,
        mate1: 0,
        hcso: 0,
        cien: 0,
        mate2: 0,
      });
    } else {
      return postulante.maximosPuntajes || {
        mate1: 0,
        mate2: 0,
        leng: 0,
        cien: 0,
        hcso: 0
      };
    }
  },
  getPuntajes({ postulante }, getters, { modo }) {
    if (modo == 'simulación') {
      return JSON.parse(JSON.stringify(postulante.puntajes)).map((puntaje, i) => {
        if (i == 0) {
          Object.keys(puntaje).forEach(prueba => {
            const valid = puntajesMinMax(prueba);
            if (prueba != 'rendicion') {
              puntaje[prueba] = typeof valid(puntaje[prueba]) === 'string' ? null : puntaje[prueba];
            }
          });
        }
        return puntaje;
      });
    } else {
      return postulante.puntajes.length && postulante.puntajes || [{
        mate1: null,
        mate2: null,
        leng: null,
        cien: null,
        hcso: null,
      }];
    }
  },
  getPool(state, { getMaximosPuntajes, getPuntajes }) {
    return {
      mate1: getPuntajes[getMaximosPuntajes.mate1] && getPuntajes[getMaximosPuntajes.mate1].mate1,
      mate2: getPuntajes[getMaximosPuntajes.mate2] && getPuntajes[getMaximosPuntajes.mate2].mate2,
      leng: getPuntajes[getMaximosPuntajes.leng] && getPuntajes[getMaximosPuntajes.leng].leng,
      cien: getPuntajes[getMaximosPuntajes.cien] && getPuntajes[getMaximosPuntajes.cien].cien,
      hcso: getPuntajes[getMaximosPuntajes.hcso] && getPuntajes[getMaximosPuntajes.hcso].hcso,
    };
  },
  getPromLenMat(state, { getPool }) {
    return getPool.mate1 && getPool.leng ? (getPool.mate1 + getPool.leng) / 2.0 : null;
  },
  isRankingProblema ({ postulante }) {
    if(!postulante.simulacionRanking) {
      return false;
    }
    const { nota, promedio, nem , rkg } = postulante.simulacionRanking[0] || {};
    return !nota && !promedio && !nem && !rkg;
  },
};

const actions = {
  setPostulacion({ commit, dispatch }, postulacion) {
    commit('setPostulacion', postulacion);
    dispatch('fillCarrerasSeleccionadas');
  },
  addPuntajeSimulacion({ commit, getters }, puntaje) {
    commit('setPuntajeSimulacion', puntaje);
    if (getters.tieneSeleccionadas) {
      calculadoraPsu();
    }
  },
  addNominaPostulante({ commit, getters }, nomina) {
    commit('setNominaPostulante', nomina);
    if (getters.tieneSeleccionadas) {
      calculadoraPsu();
    }
  },
  addNemRkg({ commit, getters }, { property, val }) {
    commit('setNemRkg', { property, val });
    if (getters.tieneSeleccionadas) {
      calculadoraPsu();
    }
  },
};

const mutations = {
  setPuntajesPostulante(state, puntajes) {
    state.postulante.puntajes = puntajes;
  },
  setPostulante(state, postulante) {
    if (Array.isArray(postulante.escolaridad) && postulante.escolaridad.length) {
      postulante.escolaridad.sort((a, b) => parseInt(b.curso) - parseInt(a.curso));
    }
    state.postulante = JSON.parse(JSON.stringify(postulante));
  },
  setPostulanteRankingNemPromedio(state, parametros) {
    state.postulante.ranking = parametros.ranking;
    state.postulante.nem = parametros.nem;
    state.postulante.promedioNotas = parametros.promedioNotas;
  },
  setPercentilNem(state, percentil) {
    state.postulante.percentilNem = percentil;
  },
  setPostulacion(state, postulacion) {
    state.postulacion = postulacion;
  },
  setSituaciones(state, situaciones) {
    state.situaciones = situaciones;
  },
  setSituacionesPace(state, situacionesPace) {
    state.situacionesPace = situacionesPace;
  },
  setBonificacionPreferenciaPace(state, bonificacionPreferenciaPace) {
    state.bonificacionPreferenciaPace = bonificacionPreferenciaPace;
  },
  setNotaSimulacion(state, nota) {
    state.notaSimulacion = nota;
  },
  setNominaPostulante(state, nomina) {
    state.postulante.nomina = nomina;
  },
  setPuntajeSimulacion(state, { prueba, puntaje }) {
    state.postulante.puntajes[0][prueba] = puntaje;
  },
  setMaximosPuntajes(state, maximos) {
    state.postulante.maximosPuntajes = maximos;
  },
  setNemRkg(state, { property, val }) {
    state.postulante[property] = val;
  }
};

export default {
  namespace,
  state,
  mutations,
  getters,
  actions,
};
