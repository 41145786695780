<template>
  <v-dialog persistent :max-width="500" :value="show">
    <v-card>
      <v-card-title class="headline">
        <div ref="CH" class="psd-container">
          Criterios de habilitación <d-btn-accesibilidad visible inline video-id="CH" :video-text="$textVideoPsd('CH')" />
        </div>
      </v-card-title>
      <v-card-text>
        <p>
          Para mayor información sobre los criterios de habilitación pincha
        </p>
        <p class="text-center">
          <a href="https://acceso.mineduc.cl/wp-content/uploads/2024/04/Requisitos-de-postulacion.pdf" target="_blanck" rel="noopener noreferrer">AQUÍ</a>
        </p>
      </v-card-text>
      <v-card-actions ref="CHA" class="justify-center psd-container">
        <v-btn color="primary" @click="show = false">
          Aceptar
        </v-btn>
        <d-btn-accesibilidad visible inline video-id="CHA" :video-text="$textVideoPsd('CHA')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import BtnAccesibilidad from './BtnAccesibilidad';

  export default {
    components:{
      'd-btn-accesibilidad': BtnAccesibilidad,
    },
    data() {
      return {
        hide: true,
      };
    },
    computed: {
      show: {
        get() {
          return this.hide && !this.$store.getters.tienePostulacion
        },
        set() {
          this.hide = false;
        }
      }
    }
  };
</script>
