'strict';
function urlVideos() {
  return {
    IOP   : 'vYtQ59vhJeg',
    T0    : 'qQ5gQk1L22A',
    CH    : 'TDuV9KmxG3I',
    CHA   : 'Vf98LfR19jc',
    DA0   : 'g94Oa4uBkXE',
    DA1   : 'iGsJ_9NCIec',
    DA2   : 'MrkQ7S7r2JU',
    DA3   : 'Gm86qp7DW4s',
    DA4   : 'l-USRTZGL0k',
    DA5   : 'Z_-KEeIfBvA',
    ES0   : 'i05TzSk2eJ4',
    RP0   : 'cnHzTpU_6c8',
    RP1   : 'QrKxpPxoMrk',
    RP2   : 'z5RFNvARuq8',
    RPN0  : 'BJKimCASRTk',
    PTJ0  : '2N-JJgYzWdk',
    PTJ1  : 'CEogLzj8aVc',
    PTJ2  : 'l4wZxM7AFqg',
    PTJ3  : 'XAOomuZZ-10',
    PTJ4  : 'o-Y_BOMmYGY',
    PTJ5  : '66Z0B_E63ks',
    PTJ6  : 'gFBAErlIr2Y',
    PTJ7  : 'czK-RkcGP-w',
    PTJ8  : 'CbzqhUenPR8',
    SC0   : 'IuBGYX2CCf8',
    SCT1  : '4i-We8fl6S4',
    SCT2  : 'FF9vbUUX_FI',
    SCT3  : '9XPv1aQB02Y',
    SCT4  : 'v8u6nbwtgHk',
    SCT5  : '4i-We8fl6S4',
    SCT6  : 'mgiT_CboP8o',
    SCB0  : 'hyiGnD_Wzlo',
    SCB1  : 'JikP22CzBrw',
    SCB2  : 'J1FjzazTWmY',
    SCB4  : 'G-qCgzHUfcI',
    SCB5  : 'Kb4vKH3ZK8Y',
    SCB6  : 'chb686EEPXc',
    SCB7  : 'mgiT_CboP8o',
    SCB9  : 'VxwBV9ZJr3o',
    SCB10 : 'FF9vbUUX_FI',
    P0    : 'R_NjcaGi3hI',
    P1    : 'A7pt_vMQfYQ',
    P2    : 'TfN1I3PU2ts',
    SCM0  : 'gR4y5Ae4YyE',
    SCMI0 : 'Z4n9LMmtU1w',
    SCMI1 : 'Q8kG-lOO-O8',
    PMT1  : '8l8dWHGlcOY',
    PMT2  : '7fuHIsbGy-g',
    PMT3  : 'U7snvJsNvNg',
    PMT4  : 'UbCpIavvKHk',
    PMT5  : 'o3UvE0ff4VE',
    PMT6  : 'pvsmTZ5Cy8g',
    PMT7  : 'jwXcXAP-7ng',
    PMT8  : 'MnyP-JtvZEQ',
    PMT10 : 'CBWJNGuQi3k',
    PM1   : '7YUIShE7ofY',
    PM2   : '2DTgw3EDI3Y',
    PM3   : 'Vy5hTgAMhIw',
    PM6  : 'HoW59BdH8rg',
    PM7  : 'qLUwQu4DO9o',
    PM8  : 'JiCC7dzPPgo',
    PM9  : 'dDvROniIkGA',
    PM11 : 'TYnRohUh8t0',
    PM12 : 'aNPUg5qgf2A',
    PM13 : 'cyyIdXK5-xs',
    PM14 : '-O7A5kjFIJ4',
    PM15 : 'ODkHXIMhxP4',
    PM16 : 'a9Qi1vGcmeE',
    CS0   : 'YohsNEFmTLA',
    EU0   : 'sk-2BQKDhu4',
    EU1   : 'emZgz9H5hxk',
    AN0   : 'kLqWWJiZTwM',
    AN1   : '_IT3vbFA7lY',
    SCMI2: 'y_NpbKfFtyk',
    PE1: 'X8c67zKK_MM',
    PE2: 'zlrRvsfDFso',
    PE3: 'nPrZmnLMdXI',
    CON: '23-jFGu7I4E',
    VOL: 'L_Hbc6MIc2k',
    TUT: 'oaqS68R4SYo',
    CAN: 'tg2c8i2zp5o',
    IMP1: 'PI5VQW9wqjc',
    GLOS: 'OZDQf7RoAPI',
  };
}

export default {
  urlVideos
};
