<template>
  <v-app id="inspire" :class="{'accesibilidad': accesibilidadSordos, 'no-accesibilidad': !accesibilidadSordos}">
    <v-system-bar
      class="principal"
      :color="modo.toLowerCase() == 'simulación' && 'warning' || 'success'"
      app
    >
      <v-row class="white--text d-flex align-center">
        <v-col class="text-center">
          <strong>
            {{ modo }}
          </strong>
          <span :class="[$isMobile ? 'float-end' : 'contador-carreras']">
            <strong>
              Carreras seleccionadas {{ $store.getters.numeroSeleccionadas }} / 20
            </strong>
          </span>
        </v-col>
      </v-row>
    </v-system-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      bottom
      :width="accesibilidadSordos ? 310 : 256"
    >
      <v-list-item tag="div">
        <v-list-item-content class="logo">
          <v-list-item-title class="toolbar-text">
            <div>
              <d-btn-accesibilidad inline video-id="IOP" video-text="ASISTENTE DE POSTULACIÓN" class="elevation-0" />
              ASISTENTE DE POSTULACIÓN
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list dense>
        <div class="psd-container" :class="{ 'my-1': accesibilidadSordos }">
          <v-list-item :to="{ name: 'antecedentes-personales' }" :disabled="disableAntecedentesSeleccion" router exact>
            <v-list-item-avatar color="primary" size="20" class="white--text">
              1
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Antecedentes Académicos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <d-btn-accesibilidad video-id="T0" video-text="Antecedentes Académicos" />
        </div>
        <div class="psd-container" :class="{ 'my-1': accesibilidadSordos }">
          <v-list-item :to="{ name: 'seleccion-de-carreras-regular' }" :disabled="disableAntecedentesSeleccion" router exact>
            <v-list-item-avatar color="primary" size="20" class="white--text">
              2
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Selección de Carreras
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <d-btn-accesibilidad video-id="SC0" video-text="Selección de Carreras" />
        </div>
        <div class="psd-container" :class="{ 'my-1': accesibilidadSordos }">
          <v-list-item :to="{ name: 'postulacion' }" :disabled="disabledByModo" router exact>
            <v-list-item-avatar color="primary" size="20" class="white--text">
              3
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Postulación
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <d-btn-accesibilidad video-id="P0" video-text="Postulación" />
        </div>
      </v-list>
      <v-list v-if="$isMobile && logged">
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col>
                <v-icon left>
                  account_circle
                </v-icon> <span>{{ usuario }}</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item v-for="item in profile" :key="item.title" @click="item.action">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="secondary" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-app-bar-title>
        <div :ref="$route.meta.pesd">
          <span class="text-subtitle-2 text-sm-h6">
            {{ $route.meta.title?.toUpperCase() }}
          </span>
          <d-btn-accesibilidad v-if="$route.meta.pesd" inline :video-id="$route.meta.pesd" :video-text="$textVideoPsd($route.meta.pesd)" class="ml-2" />
        </div>
      </v-app-bar-title>
      <v-spacer />
      <div v-if="!$isMobile">
        <v-menu v-if="logged" offset-y>
          <template #activator="{ on }">
            <v-btn
              text
              v-on="on"
            >
              <v-icon left>
                account_circle
              </v-icon>
              <span class="hidden-sm-only text-truncate" style="max-width:300px">
                {{ usuario }}
              </span>
              <v-icon>expand_more</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in profile" :key="item.title" @click="item.action">
              <v-list-item-title> <v-icon>{{ item.icon }}</v-icon> {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main>
      <v-container id="contenedor">
        <v-fade-transition leave-absolute>
          <router-view ref="routerComponent" />
        </v-fade-transition>
        <span v-if="$isMobile" class="principal" style="position: fixed; right: 0px; bottom: 10px;">
          <d-btn-accesibilidad visible @click.native="switchAccesibilidadSordos" />
        </span>
      </v-container>
      <d-refresher />
      <d-notifier />
      <d-profile jwt mensaje="Al presionar el botón Continuar se redireccionará a otro sitio cerrando la sesión actual." />
    </v-main>
    <d-footer />
    <v-bottom-navigation
      class="principal"
      app
    >
      <v-row class="d-flex justify-space-around align-center">
        <v-col class="text-center">
          <v-btn
            class="mr-4"
            href="https://demre.cl/mesa-de-ayuda/glosario/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="secondary--text">Conceptos Clave</span>
            <v-icon color="secondary">
              $book
            </v-icon>
          </v-btn>
          <d-btn-accesibilidad inline video-id="GLOS" video-text="Glosario" />
        </v-col>
        <v-col v-if="showBtnTutorial" class="text-center">
          <v-btn
            class="mr-4"
            @click="openTutorial"
          >
            <span class="secondary--text">Tutorial</span>
            <v-icon color="secondary">
              help
            </v-icon>
          </v-btn>
          <d-btn-accesibilidad inline video-id="TUT" video-text="Tutorial" />
        </v-col>
        <v-col class="text-center">
          <d-btn-accesibilidad visible inline @click.native="switchAccesibilidadSordos" />
        </v-col>
      </v-row>
    </v-bottom-navigation>
    <v-dialog v-model="cerrarSesion" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          <div ref="CS0" class="psd-container">
            Cerrar Sesión
            <d-btn-accesibilidad video-id="CS0" :video-text="$textVideoPsd('CS0')" class="ml-2" />
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <strong>Importante:</strong> Al presionar el botón Continuar se cerrará tu sesión, si no has confirmado tu postulación se perderá lo avanzado<br>
              ¿Quieres continuar de todos modos?
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn color="error" text transition="fade-transition" @click="cancelar">
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="secondary" text transition="fade-transition" @click="continuar">
              Continuar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <d-dialog-habilitacion />
    <d-video-psd />
    <d-loading />
  </v-app>
</template>

<script>
  import Refresher from '@demre/client-common/src/components/jwt-refresher';
  import Profile from '@demre/client-common/src/components/profile';
  import Footer from '@demre/client-common/src/components/footer';
  import Notifier from '@demre/client-common/src/components/notifier';
  import DialogHabilitacion from './components/DialogHabilitacion';
  import BtnAccesibilidad from './components/BtnAccesibilidad';
  import videoPsd from './components/Video';
  import DLoading from '@demre/client-common/src/components/loader';

  export default {
    components: {
      'd-refresher': Refresher,
      'd-profile' : Profile,
      'd-footer': Footer,
      'd-notifier': Notifier,
      'd-dialog-habilitacion': DialogHabilitacion,
      'd-btn-accesibilidad': BtnAccesibilidad,
      'd-video-psd': videoPsd,
      DLoading,
    },
    data: () => ({
      drawer: null,
      titulo: '',
      videoId:'',
      cerrarSesion: false,
    }),
    computed: {
      modo() {
        return this.$store.state.modo.toUpperCase();
      },
      disabledByModo() {
        const modo = this.$store.state.modo;
        return modo == 'preoficial' || modo == 'consulta';
      },
      profile() {
        return [
          { title: 'Cambiar contraseña y/o Email', icon: 'edit', action: () => this.$store.commit('setProfile', true) },
          { title: 'Salir', icon: 'power_settings_new', action: () => this.cerrarSesion = true }
        ];
      },
      logged : () => true,
      postulante() {
        return this.$store.state.Postulante.postulante;
      },
      usuario() {
        const { nombres, paterno, materno } = this.postulante;
        return `${nombres} ${paterno} ${materno && materno.length > 1 ? materno : ''}`.trim();
      },
      accesibilidadSordos () {
        return this.$store.state.accesibilidadSordos;
      },
      showBtnTutorial() {
        const show = !this.$store.getters.tienePostulacion || this.$store.getters.getModificar;
        const routes = this.$route.name == 'seleccion-de-carreras-regular' || this.$route.name == 'postulacion';
        return show && routes;
      },
      disableAntecedentesSeleccion() {
        return this.disabledByModo || (this.$store.getters.tienePostulacion && !this.$store.getters.getModificar);
      }
    },
    async mounted() {
      try {
        const { simulacionRanking, insTipo } = this.postulante;
        if (this.$store.state.modo == 'simulación' && !(insTipo == 4 || insTipo == 8)) {
          if (simulacionRanking && simulacionRanking.length == 1) {
            const [{ promedio, rkg, nem }] = simulacionRanking;
            this.$store.commit('setPostulanteRankingNemPromedio', {
              promedioNotas: promedio?.toFixed(2),
              ranking: rkg,
              nem
            });
          }
        }
      } catch (e) {
        this.$store.commit('error-notification', 'Se detectó alguna inconsistencia en la información, vuelve a intentar.');
      }
    },
    methods: {
      cancelar() {
        this.cerrarSesion = false;
      },
      async continuar() {
        await this.$store.dispatch('session/logout');
      },
      switchAccesibilidadSordos() {
        this.$store.commit('switchAccesibilidadSordos');
      },
      openTutorial() {
        this.$refs.routerComponent.showTutorial({ required: false });
      }
    },
  };
</script>

<style>
  /* hack para centrar el placeholder del input de las notas de 4to */
  .centerInput input {
    text-align: center;
  }
  .v-card__title {
    word-break: break-word;
  }
  tbody tr:hover {
    background: inherit !important;
  }
  tbody tr:nth-child(even) {
   background-color: #f2f2f2 !important;
  }
  .introjs-tooltipReferenceLayer {
    visibility: visible !important;
  }
</style>

<style scoped>

@media print {
  aside,footer,nav, header {
    display: none;
  }
  .v-main {
      padding: unset !important;
  }
  /* hide notifications */
  .v-snack {
    display: none;
  }
}

.contador-carreras {
  position: fixed;
  right: 15px;
}
</style>

