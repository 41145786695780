<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row class="justify-center">
          <v-col sm="8">
            <v-card>
              <div class="d-flex justify-center flex-wrap">
                <img src="https://archivos.demre.cl/public/logo_demre_200.png" class="pa-4">
                <img src="https://archivos.demre.cl/public/logo_subsecretaria_ed_200.png" class="pa-4">
              </div>
              <v-card-text class="text-center mt-4">
                <h3>Estimado postulante:</h3>
                <div class="psd-container">
                  <!-- eslint-disable vue/no-v-html -->
                  <p v-html="mensaje" />
                  <d-btn-accesibilidad v-if="pesd" visible inline :video-id="pesd" video-text="Impedimento" />
                </div>
              </v-card-text>
              <v-card-actions class="justify-center pb-6">
                <v-btn color="secondary" @click="$store.dispatch('session/logout')">
                  Salir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <d-video-psd v-if="pesd" />
  </v-app>
</template>


<script>

export default {
  components: {
    'd-btn-accesibilidad': () => import('./components/BtnAccesibilidad.vue'  /* webpackChunkName: 'btn-accesibilidad' */),
    'd-video-psd': () => import('./components/Video.vue'  /* webpackChunkName: 'videos-pesd' */),
  },
  props: {
    mensaje: {
      type: String,
      default: '',
    },
    pesd: {
      type: String,
      default: null,
    }
  }
};
</script>
