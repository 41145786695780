import Vue from 'vue'
import App from './App.vue'
import Impedimento from './Impedimento.vue';
import vuetify from './plugins/vuetify';
import archivos from '@demre/client-common/src/plugins/archivos';
import router from './router'
import store from './store';
import graphql from './utils/graphql';
import DateDB from '@demre/date-db';
import isMobile from './plugins/isMobile';
import VueTheMask from 'vue-the-mask';
import { getPayload, padLeft } from './utils';


Vue.use(archivos);
Vue.use(isMobile);
Vue.use(VueTheMask);

Vue.config.productionTip = false;

async function init() {
  const query = {
    query: `
      query {
      init {
        periodo
        modo
        finPostulacion
        postulante {
          tipo_id
          numero_id
          dv
          nombres
          paterno
          materno
          documentacion
          sexo
          nem
          ranking
          insTipo
          promedioNotas
          percentilNem
          puntajes {
            rendicion
            mate1
            mate2
            leng
            cien
            hcso
          }
          maximosPuntajes {
            mate1
            mate2
            leng
            cien
            hcso
          }
          escolaridad {
            curso
            anio
            promedio
            ued
            situacion
          }
          nomina {
            codigo
            carCodigo
            uniCodigo
            puntaje
            carrera {
              nombre
              universidad {
                nombre
              }
            }
          }
          pace {
            bonAsist
            regionEgreso
            criterio1
            criterio2
            criterio3
            iesPace
            tipoPace
          }
          simulacionRanking {
            nota
            promedio
            nem
            rkg
          }
          noInscrito
          bea
        }
        postulacion {
          estadoPostulacion
          folioPostulacion
          fechaPostulacion
          carCodigo1Prefer
          carCodigo2Prefer
          carCodigo3Prefer
          carCodigo4Prefer
          carCodigo5Prefer
          carCodigo6Prefer
          carCodigo7Prefer
          carCodigo8Prefer
          carCodigo9Prefer
          carCodigo10Prefer
          carCodigo11Prefer
          carCodigo12Prefer
          carCodigo13Prefer
          carCodigo14Prefer
          carCodigo15Prefer
          carCodigo16Prefer
          carCodigo17Prefer
          carCodigo18Prefer
          carCodigo19Prefer
          carCodigo20Prefer
          verificacion
        }
        rendiciones {
          rendicion
          nombre
        }
        roles {
          nombre
        }
        inicio
        fechaActual
      }
    }`,
  };
  const { init } = await graphql.request(query);
  if (!init) {
    throw new Error('No se pudo recuperar datos, por favor intente más tarde');
  }

  init.rendiciones = init.rendiciones.reduce((acc, { rendicion, nombre }) => {
    acc[rendicion] = nombre;
    return acc;
  }, {});

  window.DATA = init;
  const { username } = getPayload();
  window.DATA.profileUrl = `${process.env.VUE_APP_CADES_CLIENT}/profile?appId=${process.env.VUE_APP_CADES_ID}&username=${username}`;

  /* seteo de datos */
  if (window.DATA.modo) {
    store.commit('setModo', window.DATA.modo);
  }
  //si estoy en simulacion y tengo pruebas especiales las saco.
  if (store.state.modo == 'simulación') {
    window.DATA.postulante.nomina = window.DATA.postulante.nomina.map(nomina => {
      if (nomina.codigo == 1 || nomina.codigo == 2) {
        return {
          carCodigo: nomina.carCodigo,
          codigo: nomina.codigo,
          nombre: nomina.carrera.nombre,
          pruebaExcluyente: nomina.codigo == 1,
          puntaje: nomina.puntaje,
          universidad: nomina.carrera.universidad.nombre,
          notDelete: true,
        };
      }
      return nomina;
    });
    const postulante = window.DATA.postulante;
    const percentilNemOriginal = postulante.percentilNem;
    store.registerModule('simulaciones', {
      state: {
        pedagogia: !percentilNemOriginal,
        nem: !postulante.nem,
        ranking: !postulante.ranking,
      }
    });
    if(!postulante.nem) {
      store.registerModule('nem', {
        state: {
          simulacion: true,
        }
      });
    }
    document.body.classList.add('simulacion-water-mark')
  }
  store.commit('setPostulante', window.DATA.postulante);
  store.commit('setPeriodo', parseInt(window.DATA.periodo));
  store.commit('setFechaInicioPostulacion', new DateDB(window.DATA.inicio));
  store.commit('setFechaServer', new DateDB(window.DATA.fechaActual));
  store.commit('setRoles', window.DATA.roles);

  store.commit('setSituaciones', window.INIT.situaciones);
  if (store.state.Postulante.postulante.pace) {
    store.commit('setSituacionesPace', window.INIT.paceSituaciones);
    store.commit('setBonificacionPreferenciaPace', window.INIT.paceBonificacionPreferencias);
  }

  // https://gist.github.com/gordonbrander/2230317
  store.commit('setClientSession', '_' + Math.random().toString(36).substr(2, 9));

  const regionesMap = window.INIT.regiones.reduce((acc, region) => {
    acc[region.regCodigo] = region.regNombre;
    return acc;
  }, {});

  const universidadesMap = window.INIT.universidades.reduce((acc, universidad) => {
    acc[universidad.codigo] = universidad;
    return acc;
  }, {});

  window.INIT.carreras.forEach(carrera => {
    carrera.regNombre =  regionesMap[carrera.regCodigo]
    carrera.codigo = parseInt(`${carrera.uniCodigo}${padLeft(carrera.codigo, 3)}`);
    carrera.uniNombre = universidadesMap[carrera.uniCodigo].nombre;
    carrera.sigla = universidadesMap[carrera.uniCodigo].sigla;
    carrera.disabled = false;
    carrera.regular = true;
    carrera.pace = store.state.Postulante.postulante.pace ? carrera.pace == 'S' && !!carrera.vacantesPace : false;
  });
  // load INIT json
  store.commit('setUniversidades', window.INIT.universidades);

  store.commit('setTiposAgrupacionSies', { tiposAgrupacionSies : window.INIT.tiposAgrupacionSies });
  store.commit('setRegiones', window.INIT.regiones);
  if (window.DATA.postulacion) {
    store.dispatch('setPostulacion', window.DATA.postulacion);
  }
}

store.dispatch('session/load')
  .then(init)
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(err => {
    const errorCode = err.original?.[0]?.extensions?.code;
    const pesdErrorCode = new Map([
      ['E0015D', 'IMP1'],
    ]);

    const pesd = pesdErrorCode.get(errorCode);

    const mensaje = err.message;
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(Impedimento, { props: { mensaje, pesd } })
    }).$mount('#app')
  })

window.store = store;
function salirAdvertencia (e) {
  const mensaje = 'saliendo de aplicación';
  e.returnValue = mensaje;
  return mensaje;
}
window.onbeforeunload = salirAdvertencia;
