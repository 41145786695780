import Store from './store';
import VueRouter from 'vue-router';
import Vue from 'vue';

/* common */
const Error404 = () => import('./pages/Error' /* webpackChunkName: 'err404' */);

/* pages */
const AntecedentesPersonales = () => import('./pages/AntecedentesPersonales/Index' /* webpackChunkName: 'antecedentes-personales' */);
const SeleccionCarrerasRegulares = () => import('./pages/SeleccionDeCarreras/Index' /* webpackChunkName: 'seleccion-carreras-regulares' */);
const Postulacion = () => import('./pages/Postulacion/Index' /* webpackChunkName: 'postulacion' */);
const PreOficial = () => import('./pages/PreOficial.vue' /* webpackChunkName: 'espera' */);

const routes = [
  { path: '/', redirect: { name: 'antecedentes-personales' } },
  {
    path: '/antecedentes-personales',
    name: 'antecedentes-personales',
    component: AntecedentesPersonales,
    meta: {
      title: 'Antecedentes Académicos',
      pesd: 'T0',
    }
  },
  {
    path: '/seleccion-de-carreras-regulares',
    name: 'seleccion-de-carreras-regular',
    component: SeleccionCarrerasRegulares,
    meta: {
      title: 'Selección de Carreras',
      pesd: 'SC0',
    }
  },
  {
    path: '/postulacion',
    name: 'postulacion',
    component: Postulacion,
    meta: {
      title: 'Postulación',
      pesd: 'P0',
    }
  },
  {
    path: '/espera',
    name: 'preoficial',
    component: PreOficial,
    beforeEnter: (to, from, next) => {
      if (Store.state.modo != 'preoficial') {
        next({ name : 'antecedentes-personales' });
      } else {
        next();
      }
    },
    meta: {
      title: 'Sala de espera'
    }
  },
  {
    path: '*',
    name: 'error',
    component: Error404,
    meta: {
      title: 'Error'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Asistente de Postulación';
  }
  if (to.name == 'logout' || to.name == 'profile') {
    return next();
  }
  if (Store.state.modo == 'preoficial' && to.name != 'preoficial') {
    return next({ name: 'preoficial' });
  }
  if (Store.state.modo == 'simulación' && !(Store.getters.tienePostulacion && !Store.getters.getModificar) && !from.name && to.name != 'antecedentes-personales') {
    return next({ name: 'antecedentes-personales' });
  }
  if (Store.state.modo == 'consulta' && to.name != 'postulacion') {
    return next({ name: 'postulacion' });
  }
  if (to.name != 'postulacion' && (Store.getters.tienePostulacion && !Store.getters.getModificar)) {
    return next({ name: 'postulacion' });
  }
  next();
});

Vue.use(VueRouter);

export default router;
