
import store from '../store';
import { puntajeRegular, puntajeRegularPace } from '@demre/calculadora-puntajes';

async function calculadoraPsu(prefs, origen) {
  const postulante = JSON.parse(JSON.stringify(store.state.Postulante.postulante));
  const situaciones = store.state.Postulante.situaciones;
  let preferencias = [];
  if (prefs) {
    preferencias = prefs;
  } else {
    preferencias = JSON.parse(JSON.stringify(store.state.Carreras.carrerasRegularesSeleccionadas));
  }
  const args = {
    preferencias,
    situaciones,
    postulante,
  };
  args.carreras = await store.dispatch('checkCarrerasCache', { preferencias });
  args.pool = store.getters.getPool;
  if (!postulante.pace) {
    puntajeRegular(args);
  } else {
    const {situacionesPace, bonificacionPreferenciaPace} = store.state.Postulante;
    args.situaciones = {
      situacionesRegulares: situaciones,
      situacionesPace: situacionesPace
    };
    args.bonificacionPref = bonificacionPreferenciaPace;
    puntajeRegularPace(args);
  }
  if (!origen) {
    store.commit('setCarrerasSeleccionadasAll', preferencias);
  }
}

export {
  calculadoraPsu,
};
