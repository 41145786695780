const state = {
  selectUniversidades: [],
};

const mutations = {
  async setUniversidades(state, universidades) {
    state.selectUniversidades = universidades.sort((a, b) => a.nombre.localeCompare(b.nombre));
  }
};

export default {
  state,
  mutations
};
