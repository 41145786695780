<template>
  <v-dialog :value="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ text }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon dark @click="cancelar">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <iframe ref="iframe" width="100%" height="670" :src="url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" dark @click="cancelar">
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import videos from '../utils/videos';
  import Vue from 'vue';
  import Vuetify from 'vuetify';

  Vue.use(vue => {
    vue.mixin({
      methods: {
        $textVideoPsd(id) {
          const self = this;
          return function() {
            return self.$refs[id] && self.$refs[id].firstChild.textContent;
          };
        }
      }
    });
  });
  Vue.use(Vuetify);

  export default {
    data: () => ({
      dialog: false,
    }),
    computed: {
      url() {
        const obj = videos.urlVideos();
        return this.id ? `https://www.youtube.com/embed/${obj[`${this.id}`]}?rel=0&amp;autoplay=1&start=1` : '';
      },
      id() {
        return this.$store.state.videoPsd.id;
      },
      text() {
        return this.$store.state.videoPsd.text;
      },
    },
    watch: {
      dialog: function(val) {
        if (val && !!this.$refs.iframe) {
          this.$refs.iframe.src = this.url;
        }
      }
    },
    created() {
      this.$store.registerModule('videoPsd', {
        state: {
          id: null,
          text: null
        },
        mutations: {
          'setVideoPsd': (state, { id, text }) => {
            state.id = id;
            state.text = text;
          }
        }
      });
      this.$store.watch(state => state.videoPsd, {
        handler: ({ id, text }) => {
          if (id && text) {
            this.dialog = true;
          }
        },
        deep: true
      });
    },
    methods: {
      cancelar : function () {
        this.$store.commit('setVideoPsd', {
          id: null,
          text: null,
        });
        this.dialog = false;
      }
    }
  };
</script>
