import { calculadoraPsu } from '../../utils/puntajes';
import { eliminaTildes } from '../../utils';
import DemreError from '@demre/error';
import graphql from '../../utils/graphql';

const state = {
  tiposAgrupacionSies: [],
  carrerasRegularesSeleccionadas: [],
  cacheCarreras: {},
  carrerasPruebaEspecialRegular: [],
  observacionDialog: false,
  deferredObservacion: {},
};

const getters = {
  numeroSeleccionadas(state) {
    return state.carrerasRegularesSeleccionadas.length;
  },
  tieneSeleccionadas(state, getters) {
    return !!getters.numeroSeleccionadas;
  },
  maximoSeleccionadas(state, getters) {
    return getters.numeroSeleccionadas >= 20;
  },
  carrerasFiltradas(state, getters, rootState) {
    const { codUniSeleccionada, tiposAgrupacionSiesSeleccionada, codRegionSeleccionada, busquedaPorNombre } = rootState;
    const filters = [
      ...codUniSeleccionada.length && [({ uniCodigo }) => codUniSeleccionada.includes(uniCodigo)] || [],
      ...codRegionSeleccionada.length && [({ regCodigo }) => codRegionSeleccionada.includes(regCodigo)] || [],
      ...busquedaPorNombre?.length > 2 && [({ nombre }) => eliminaTildes(nombre.toLowerCase()).includes(eliminaTildes(busquedaPorNombre.toLowerCase()))] || [],
      ...tiposAgrupacionSiesSeleccionada.length && [({ tasCodigo }) => tiposAgrupacionSiesSeleccionada.includes(tasCodigo)] || []
    ];
    if(!filters.length) {
      return [];
    }
    return window.INIT.carreras.filter(carrera => {
      return filters.every(filter => filter(carrera));
    }).sort((a, b) => a.nombre.localeCompare(b.nombre));
  },
  getCarrerasCache: state => ({ secuencias }) => {
    return secuencias.map(secuencia => state.cacheCarreras[secuencia]);
  },
  checkMuestraMujeres: (state, getters, rootState ) => ({ ingresoMujeres, vacantesMujeres }) => {
    const { Postulante: { postulante : { sexo } } } = rootState;
    const evalSexo = sexo == 2;
    const evalRegular = ingresoMujeres == 'S' && vacantesMujeres > 0;
    return evalSexo && evalRegular;
  },
};

const actions = {
  async delCarrerasSeleccionadas({ commit, getters }, codigo) {
    commit('delCarrerasRegularesSeleccionadas', codigo);
    if (getters.tieneSeleccionadas) {
      calculadoraPsu();
    }
  },
  fillCarrerasSeleccionadas({ commit, getters }) {
    if (!getters.tieneSeleccionadas) {
      commit('setCarrerasSeleccionadasAll', getters.carrerasPostulacion);
      calculadoraPsu().catch(e => {
        commit('error-notification', e.message);
      })
    }
  },
  formatSaveData({ state, rootState }) {
    const { clientSession, Postulante } = rootState;
    const { postulante: { pace } } = Postulante;
    return {
      clientSession,
      cumpleC1: pace && pace.criterio1 || null,
      cumpleC2: pace && pace.criterio2 || null,
      ...state.carrerasRegularesSeleccionadas.reduce((acc, { codigo, ptjeSeleccion, observacion }, index) => {
        acc[`carCodigo${ index + 1 }Prefer`] = codigo;
        acc[`puntajeCarr${ index + 1 }`] = parseFloat(ptjeSeleccion);
        if (observacion) {
          acc[`observacionCarr${ index + 1 }`] = typeof observacion === 'string' ? observacion : observacion.join('; ');
        }
        return acc;
      }, {})
    };
  },
  addCarrerasRegularesSeleccionadas({ state, commit, dispatch }, { carrera, tutorial = false }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const preferencias = [
          ...JSON.parse(JSON.stringify(state.carrerasRegularesSeleccionadas)),
          carrera
        ];
        if (!tutorial) {
          try {
            await calculadoraPsu(preferencias, 'agregar');
          } catch(e) {
            return reject(e);
          }
        }
        const preferencia = preferencias.find(({ codigo }) => codigo == carrera.codigo);
        const agrega = await dispatch('verifyObservacion', { preferencia });
        if(!agrega) {
          return reject(new DemreError({ message: 'Preferencia no agregada' }));
        }
        commit('setCarrerasRegularesSeleccionadas', carrera);
        commit('success-notification', `Se ingresó correctamente la carrera <strong>${carrera.nombre}</strong>`);
        resolve();
      })();
    })
  },
  verifyObservacion({ commit, getters }, { preferencia } ) {
    return new Promise((resolve, reject) => {
      const showDialogObservacion = !!preferencia.observacion || !!preferencia.observacionPace
      if (!showDialogObservacion) {
        return resolve(1);
      }
      preferencia.pedagogia = getters.getCarrerasCache({ secuencias : [ preferencia.secuencia ]})[0].pedagogia;
      commit('setDeferredObservacion', { resolve, reject, preferencia });
    });
  },
  checkCarrerasCache({ state, dispatch, getters }, { preferencias }) {
    return new Promise((resolve, reject) => {
      const carreras = [...(preferencias.length ? [...preferencias] : [state.carrerasRegularesSeleccionadas])];
      const sinCache = carreras.filter(carr => !state.cacheCarreras[carr.secuencia]);
      const ids = carreras.map(({ secuencia }) => secuencia);
      if(!sinCache.length) {
        return resolve(getters.getCarrerasCache({ secuencias: ids }));
      }
      dispatch('addCarreraCache', { ids }).then(() => {
        return resolve(getters.getCarrerasCache({ secuencias: ids }));
      }).catch(reject);
    })
  },
  addCarreraCache({ commit }, { ids }) {
    return new Promise((resolve, reject) => {
      const query = {
        query: `query carreraRegular($ids: [Int]!) {
          inforegulares: carreraByIds(ids: $ids) {
            secuencia
            vacantes1Sem
            vacantes2Sem
            puntajePonderadoMinimo
            sexo
            pruebaEspecial
            pruebaExcluyePondera
            pctPruebaPondera
            puntajeMinPrueba
            pctNotas
            pctRkg
            maximoPublicar
            promedioMinimo
            puntajeMinimoRkg
            pedagogia
            seleccionPedagogia
            ingresoMujeres
            vacantesMujeres
            ponderaciones {
              pruCodigo
              porcentaje
              alternativa
            }
            universidad {
              regionCasaCentral
            }
          }
        }`,
        variables: {
          ids
        }
      };
      graphql.request(query).then(({ inforegulares }) => {
        const carreras = inforegulares.map(carrera => {
          const { codigo, nombre, lugarImparte,  regCodigo, tasCodigo, uniCodigo, uniNombre, vacantesPace } = window.INIT.carreras.find(({ secuencia }) => secuencia == carrera.secuencia);
          carrera.codigo = codigo;
          carrera.nombre = nombre;
          carrera.lugarImparte = lugarImparte;
          carrera.regCodigo = regCodigo;
          carrera.tasCodigo = tasCodigo;
          carrera.uniCodigo = uniCodigo;
          carrera.universidad.nombre = uniNombre;
          carrera.vacantesPace = vacantesPace;
          return carrera;
        });
        commit('setCacheCarreras', carreras);
        resolve();
      }).catch(({ message }) => {
        reject(new DemreError({ message }));
      });
    });
  },
};

const mutations = {
  setTiposAgrupacionSies(state, { tiposAgrupacionSies }) {
    state.tiposAgrupacionSies = tiposAgrupacionSies;
  },
  setCarrerasRegularesSeleccionadas(state, carrera) {
    state.carrerasRegularesSeleccionadas.push(carrera);
  },
  delCarrerasRegularesSeleccionadas(state, carreraId) {
    state.carrerasRegularesSeleccionadas = state.carrerasRegularesSeleccionadas.filter(carrera => carrera.codigo !== carreraId);
  },
  setCarrerasSeleccionadasAll(state, carrera) {
    state.carrerasRegularesSeleccionadas = carrera;
  },
  setMoverPosicionSeleccionadas(state, indices) {
    const newIndex = indices[0];
    const oldIndex = indices[1];
    state.carrerasRegularesSeleccionadas.splice(newIndex, 0, state.carrerasRegularesSeleccionadas.splice(oldIndex, 1)[0]);
    calculadoraPsu();
  },
  setCacheCarreras(state, carreras) {
    carreras.forEach(carrera => {
      state.cacheCarreras[carrera.secuencia] = carrera;
    });
  },
  setCarrerasPruebaEspecialRegular(state, carreras) {
    state.carrerasPruebaEspecialRegular = carreras;
  },
  setObservacionDialog(state, val) {
    state.observacionDialog = val;
  },
  setDeferredObservacion(state, val) {
    state.deferredObservacion = val;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
