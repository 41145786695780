import Vue from 'vue';
import Vuex from 'vuex';
import Universidades from './modules/universidades';
import Carreras from './modules/carreras';
import Postulante from './modules/postulante';
import session from '@demre/client-common/src/components/jwt-refresher/session';
import router from '../router';

Vue.use(Vuex);

const state = {
  sesion: false,
  page: 1,
  itemsXPage: 15,
  regiones: [],
  modo: '',
  periodo: null,
  fechaInicioPostulacion: null,
  fechaServer: null,
  fechaServerDiff: 0,
  clientSession: null,
  roles: null,
  codUniSeleccionada: [],
  tiposAgrupacionSiesSeleccionada: [],
  codRegionSeleccionada: [],
  busquedaPorNombre: null,
  search: '',
  accesibilidadSordos: false,
  modificar: false,
  formPuntajes: null,
  formEscolaridad: null,
  loading: false,
};

const actions = {
  setErrorNotification({ commit }, e) {
    commit('error-notification', e.name == 'DemreError' || e.isDemreError ? e.message : 'Ocurrió un error, por favor intenta nuevamente en unos minutos');
  },
  reset({ commit }) {
    commit('setCodUniSeleccionada', []);
    commit('setTiposAgrupacionSiesSeleccionada', []);
    commit('setCodRegionSeleccionada', []);
    commit('setBusquedaPorNombre', null);
    commit('setSearch', '');
  }
};

const getters = {
  getModificar({ modificar }) {
    return modificar;
  },
  isSimulacion({ modo }) {
    return modo.toLowerCase() == 'simulación';
  },
};

const createSetter = prop => (state, value) => state[prop] = value;
const mutations = {
  setSesion: createSetter('sesion'),
  setRegiones: createSetter('regiones'),
  setModo: createSetter('modo'),
  setPeriodo: createSetter('periodo'),
  setFechaInicioPostulacion: createSetter('fechaInicioPostulacion'),
  setFechaServer: (state, fecha) => {
    state.fechaServer = fecha;
    state.fechaServerDiff = fecha.getTime() - Date.now();
  },
  setClientSession: createSetter('clientSession'),
  setRoles: createSetter('roles'),
  setCodUniSeleccionada: createSetter('codUniSeleccionada'),
  setTiposAgrupacionSiesSeleccionada: createSetter('tiposAgrupacionSiesSeleccionada'),
  setCodRegionSeleccionada: createSetter('codRegionSeleccionada'),
  setBusquedaPorNombre: createSetter('busquedaPorNombre'),
  setBusquedaTabs: createSetter('busquedaTabs'),
  setSearch: createSetter('search'),
  switchAccesibilidadSordos: (state) => state.accesibilidadSordos = !state.accesibilidadSordos,
  setModificar: createSetter('modificar'),
  setFormPuntajes: createSetter('formPuntajes'),
  setFormEscolaridad: createSetter('formEscolaridad'),
  setPage: createSetter('page'),
  setItemsXPages: createSetter('itemsXPage'),
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV == 'development',
  state,
  actions,
  mutations,
  getters,
  modules: {
    Universidades,
    Carreras,
    Postulante,
    session,
  }
});
store.watch((state, getters) => getters.maximoSeleccionadas, maximoSeleccionAlcanzada => {
  if(maximoSeleccionAlcanzada && (!store.getters.tienePostulacion || store.getters.getModificar)) {
    store.commit('notification', {
      text: 'Se alcanzó el número máximo de preferencias',
      color: 'primary',
    });
    setTimeout(() => {
      if(router.currentRoute.name != 'postulacion') {
        router.push({ name: 'postulacion' });
      }
    }, 2000);
  }
});
export default store;
