<template>
  <v-btn
    dark
    small
    :class="['psd-btn', {'psd-toggle': !visible, 'psd-right-middle': !inline }]"
    @click="click"
  >
    <i :class="iconDark ? 'accessibility-dark':'accessibility'" />
  </v-btn>
</template>

<script>
  export default {
    props: {
      videoId: {
        type: String,
        default: null,
      },
      videoText: {
        type: [String, Function],
        default: null,
      },
      visible: {
        type: Boolean,
        default: false,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      iconDark: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      click() {
        this.$store.commit('setVideoPsd', {
          text: typeof this.videoText === 'function' ? this.videoText() : this.videoText,
          id: this.videoId,
        });
      }
    },
  };
</script>

<style>
  .v-application.no-accesibilidad .psd-toggle {
    display: none;
  }

  .v-application.accesibilidad .psd-container {
    position: relative;
    padding-right: 80px;
  }

  .v-application.mobile.accesibilidad .psd-container, .v-application.accesibilidad .nota.psd-container {
    padding-right: 50px;
  }

  .v-application.mobile .psd-btn {
    right: 0;
  }

  .psd-btn {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    background-color: #00609C !important;
  }

  .psd-btn i {
    min-width: 35px;
    min-height: 35px;
    display: inline-block;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  .psd-btn .accessibility {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjBAMAAADs965qAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAnUExURQBgnPf396a4y8rV37vI10p5puvv8tvj6ZGowWaKrytrn3+buRVjnJPwwN0AAAD9SURBVCjPY2CgNgjFEOExwRBiyYxwn4AqpNgsKByAIsIpnOi9AFXRcsEkcxNUVY6ChYKC2cgirIKCYg0cyRBOK5hkEhQUMW4AybVoqAuDhdgFgUB4Ac+mQhADbOZEoEYjQYPjgmAAUs5gKChoxRBWsFEwDSSkABQJBkuxFDhu4EqECimC6eMGJRBmA1QodamiAkRIiAEqJAa0KW0CZyFEH0Mj2CIRBkfhQpgbAkGqgN4+AnYe2KXMIjt3AAOLZ8EWFUFBCYgXIWHAnBQJNDULJdASBcUKIabDwSIXJahRCDCpsDgVVSRcMEVxA6rQZO2TQphx54AhxLWA6skIAEWAMXv6Xz3+AAAAAElFTkSuQmCC);
  }

  .psd-btn .accessibility-dark{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAMAAAApB0NrAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAACXBIWXMAAC4jAAAuIwF4pT92AAAC3FBMVEUAN2T9/vwBN2cAN2b///4AOYP+//7////+/v7+/v39/v0AN2f9/f0AN2gBN2gBOYMAN2UCOGn//v78/f319/mMla37+/uepLnO095reZn4+flseZr8/fwBOIPr7fD9/fzu8fTe4ejP093P1N7Y3OT19/j6/PvS1t/l6O3u8fPo6/EKOmtba4/l5+3R1d61usi5v8/MztjKzNjU1+AXPnHd4ejKz9mHkKrU1+EFOGs0Tnvt7/JQYosxS3s2T3ygqLxgcJSss8S3vczi5etyfp2XnrXQ1d3P1d1VZY3k5uyTnLWMla5kc5bGy9ZZaI+8wMudpbuOl69wfZv9/f4nRnj5+vvm6e7x9PcLOm2OmLQ8U4BtfaXg4+nV2eLDyNXt8PLBxtNSZZeAi6dEW5MCOYO9w9E4UYzk5+2+xNH+/vw+VICpr8L09vdNYIo6UoAAN4IRPoYaP3AvSniLla4dQYYhRHjz9fYFOWkBN4FKX5VebJBreJoFOILO1Nz8/Pvj5+zq7fBWZoz4+fqOmLDN0tzn6u0DOGmPmbYgQXH29/hUZYzBx9QTPG7N0t1teqOvtsimrcEDOGwAOIPl6O6cpbyutcZRZY7W2ODU2OD3+Pn6+/qJk6zL0NsKO4TEydUUP4Xs7/GCjafb3+bd4Ofp6u8wS3mnr8HL0NokRXnDydX7/PyDjqnq7PD4+vnW2uIBOGvM0dskRHXByNeIk7EWPnLm6O3AxNG/xNH7/Pva3uUGOWvh5evy8/aFkKvs7/Tw8/UIOW7w8vU+VIHo7PC3v9Df4un9/fuLl7Pg5OuutMXX2+RZaY78/PxXaI54hKWXn7a5vs8BOYSjq8Hp7PHr7vKJk63p7O/M0NvY3eTZ3uZ0gKD09febpLspR3eTnbjCx9MiQoajqr5pd5n8/vzk5+wVP3r19vdAVIEHOWv2+PmyuMrN0dv3+PrW2+PW2uP6+/s5UH2vtsbZ3uXm6e2IzjrmAAAB7ElEQVQYGc3BU2McURgG4Hf4nTPL2HZq27Zt2zaS2rZt27Zt2+0f6OxO0vYi071snwciPMmEf2P8KngyduUGABL+YuKKzUcvnZs7vAtMiWs2Xk4UTp+Y1Q+mUjadDD5y5bgN5jr2nbf31Jnos+d3b4MJr8bebP4+68XDkcLSEchYECfGhV0XbFuWJU3AHwpnRroqjIjCV+/ZGoRpElzq16zuVzxfXv9SXjDk9pWJSGWasH6qE5C6tmxeT2ayzFRvtTIMlVTSqb6T1oUKUzA6OrQDkylNCbjZ8zDStekdNn2y/xzM5kN94ppRmpxwKRjIZCK5ViuHooTFJCBAGDOge9u6MrnlgEtZTi5aC4eiLFm4GMtjFimK0oCTW3boLBXI0LR1t/4z+QLLgevXFEVppJKOlysKnSWLTG5abLvIcfFrbTcSnyqOwe056fKLcMuq0i9yoFO6Z016fHVGBCMioTQMxTipnHGm1WnSKWQ/8CiWM8Y0TkSFCsBQJNgnV0CZbNVq1Ibh7bfkqCfHOBGVRLqK5SX89v7DVzjffCcdq4qMPfvy+vOnjy+SNWJREkw8/xH37uXDu1Zr54YwZbO8un87fvvIXjAl3kp4wA/uGDXEDlODIm7eObQzdVgPmBP72AG/8J7wZGAI/lfiTy/be/xGreOlAAAAAElFTkSuQmCC);
  }

  .psd-right-middle {
    position: absolute !important;
    right: 20px !important;
    top: 50% !important;
    transform: translate(0, -50%) !important;
  }
</style>
