export function eliminaTildes(str) {
  str = str.toLowerCase();
  const mapa = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u'
  };
  // eslint-disable-next-line no-control-regex
  const cambio = str.replace(/[^\u0000-\u007E]/g, function (a) {
    return mapa[a] || a;
  });
  return cambio;
}

export function padLeft(data, size, paddingChar) {
  return (new Array(size + 1).join(paddingChar || '0') + String(data)).slice(-size);
}

export function componeCarCodigo(uniCodigo, carCodigo) {
  return parseInt(`${uniCodigo}${padLeft(carCodigo, 3)}`);
}

export function requiredField (nombre) {
  return v => !!v  || `${nombre} es requerido`;
}

export function isInt (nombre) {
  return v => Number.isInteger(Number(v)) || `${nombre} debe ser un número entero`;
}

export function puntajesMinMax (nombre) {
  return v => ((v <= 1000 && v >= 100) || !v) || `${nombre} debe estar entre 100 y 1000`;
}

export function getPayload() {
  const token = window.localStorage.getItem('token');
  if(token) {
    const payload = token.split('.')[1];
    const payloadStr = window.atob(payload);
    return JSON.parse(payloadStr);
  }
  return '';
}
